<template>
  <div id="home-main-div" class="home-main-div">
    <v-img
      class="home-img"
      :src="require('@/assets/images/Home-Background.jpg')"
      :lazy-src="require('@/assets/images/Home-Background.jpg')"
      gradient="180deg, rgba(103,183,110,0) 0%, rgba(103,183,110,0.7) 80%, rgba(148,192,95,1) 90%, rgba(193,198,78,1) 100%"
    >
      <div class="home-img-content">
        <h1 class="home-text-on-img">
          {{ $t("home.home-image.title") }}
        </h1>
        <a
          class="home-btn-on-img"
          :href="$i18n.locale === 'fr' ? '/explorer' : '/explore'"
          @mouseenter="pressButtonOnImg()"
        >
          {{ $t("home.home-image.button") }}
          <v-icon class="home-btn-on-img-icon" color="#4ebcde"
            >mdi-earth</v-icon
          >
        </a>
      </div>
      <v-img
        v-if="$i18n.locale === 'en'"
        class="home-earth-on-img"
        :lazy-src="require('@/assets/images/Earth-EN.png')"
        :src="require('@/assets/images/Earth-EN.png')"
        contain
      ></v-img>
      <v-img
        v-if="$i18n.locale === 'fr'"
        class="home-earth-on-img"
        :lazy-src="require('@/assets/images/Earth-FR.png')"
        :src="require('@/assets/images/Earth-FR.png')"
        contain
      ></v-img>
      <p
        v-if="!isMobile"
        style="position: absolute; bottom: 5%; left: 15%; color: white"
      >
        {{ $t("update.content") }}
        <span style="font-weight: bold">{{ lastUpdate }}</span>
      </p>
    </v-img>

    <div id="home-klover-root" class="home-klover-root-div">
      <v-img
        class="home-klover-root-img elevation-22"
        :src="require('@/assets/images/greeness.png')"
        :lazy-src="require('@/assets/images/greeness.png')"
      />
      <div>
        <h2 class="home-klover-root-title">
          {{ $t("home.klover-root.title") }}
        </h2>
        <p class="home-klover-root-text">
          {{ $t("home.klover-root.text") }}
        </p>
        <a
          class="home-klover-root-anchor"
          :href="$i18n.locale === 'fr' ? 'classement' : 'featured'"
          >{{ $t("home.klover-root.anchor") }}</a
        >
      </div>
    </div>

    <div
      ref="homeHighlight"
      class="home-highlight"
      @mouseenter="foldHighlight()"
    >
      <p class="home-highlight-div-title">
        {{ $t("home.home-highlight.title") }}
      </p>
      <HomeHighlight v-if="highlightCity" :city="highlightCity" />

      <div v-if="!highlightCity">
        <v-container style="padding: 8%">
          <p>
            Il semblerait que nous n'arrivons pas à récupérer de données
            météorologiques pour le moment. Nous essayons de régler ça au plus
            vite. Merci pour votre compréhension et à bientôt !
          </p>
        </v-container>
      </div>
    </div>

    <!-- Desktop container -->
    <v-container v-if="!isMobile" fluid style="padding: 9% 18% 1% 18%">
      <v-row align="center" justify="center">
        <v-col cols="8">
          <v-row class="mb-4">
            <v-col cols="2">
              <v-icon color="#6bba73" size="4vw">$vuetify.icon.seed</v-icon>
            </v-col>
            <v-col>
              <h2 class="home-weather-text-bloc-title">
                {{ $t("home.home-text-warning.title") }}
              </h2>
            </v-col>
          </v-row>
          <p class="home-klover-root-text" style="font-size: 1vw">
            {{ $t("home.home-text-warning.content") }}
          </p>
        </v-col>
      </v-row>
    </v-container>

    <!-- Mobile container -->
    <v-container v-if="isMobile" style="padding: 9% 10% 1% 10%">
      <v-row class="mb-2" align="center">
        <v-col cols="2">
          <v-icon style="position: relative; right: 10px" color="#6bba73" large
            >$vuetify.icon.seed</v-icon
          >
        </v-col>
        <v-col cols="10" class="pb-0">
          <h2 class="home-weather-text-bloc-title">
            {{ $t("home.home-text-warning.title") }}
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <p class="home-klover-root-text mb-10">
          {{ $t("home.home-text-warning.content") }}
        </p>
      </v-row>
      <v-row class="mb-10">
        <HomeWeather></HomeWeather>
      </v-row>
    </v-container>

    <v-img
      class="home-climate-img"
      :src="require('@/assets/images/Climate-background.jpg')"
      :lazy-src="require('@/assets/images/Climate-background.jpg')"
    >
      <div class="home-climate-img-text-section">
        <h2 class="home-climate-img-text-section-title">
          {{ $t("home.home-text-climatic-change.title") }}
        </h2>
        <p class="home-climate-img-text-section-content">
          {{ $t("home.home-text-climatic-change.legend1") }}
        </p>
        <p class="home-climate-img-text-section-content">
          {{ $t("home.home-text-climatic-change.legend2") }}
        </p>
      </div>
      <v-img
        v-if="!isMobile"
        class="home-climate-img-chart"
        eager
        :src="require('@/assets/images/Climate-Home-chart.png')"
        :lazy-src="require('@/assets/images/Climate-Home-chart.png')"
      ></v-img>
      <v-img
        v-if="isMobile"
        class="home-climate-img-chart mt-10 mb-10"
        eager
        :lazy-src="require('@/assets/images/Climate-home-chart-mobile.png')"
        :src="require('@/assets/images/Climate-home-chart-mobile.png')"
        max-width="95vw"
        contain
      ></v-img>
    </v-img>

    <v-container v-if="!isMobile" fluid style="padding: 3% 9% 1% 9%">
      <v-row>
        <v-col>
          <v-row class="mb-4">
            <v-col>
              <h2 class="home-weather-text-bloc-title">
                {{ $t("home.home-text-vegetation.title") }}
              </h2>
            </v-col>
          </v-row>
          <p class="home-klover-root-text" style="font-size: 1vw">
            {{ $t("home.home-text-vegetation.content") }}
          </p>
        </v-col>
        <v-col cols="1"> </v-col>
        <v-col v-if="highlightCity" class="ml-2" cols="6">
          <HomeWeather></HomeWeather>
        </v-col>
        <v-col v-if="!highlightCity" class="ml-2" cols="6">
          <v-img
            :lazy-src="require('@/assets/images/vegetation_picture.jpg')"
            :src="require('@/assets/images/vegetation_picture.jpg')"
            style="border-radius: 5px"
          />
        </v-col>
      </v-row>
    </v-container>

    <div class="home-presented-city">
      <p class="home-presented-city-title">
        {{ $t("home.presented-cities.title") }}
      </p>
      <HomeCityDiapo
        v-if="showCities"
        :cities="cityArray"
        :is-mobile="isMobile"
      />

      <div class="home-presented-city-paragraph">
        <a
          class="home-featured-btns"
          :href="$i18n.locale === 'fr' ? 'comparer' : 'compare'"
        >
          {{ $t("home.presented-cities.button") }}
        </a>
      </div>
    </div>

    <div class="home-our-database-div">
      <p class="home-our-database-title">
        {{ $t("home.home-database.title") }}
      </p>
      <div class="home-our-database-grid">
        <div class="home-our-database-grid-item">
          <v-icon
            v-if="!isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="4vw"
            color="#edd036"
          >
            $vuetify.icon.featured_cities_yellow
          </v-icon>
          <v-icon
            v-if="isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="50px"
            color="#edd036"
          >
            $vuetify.icon.featured_cities_yellow
          </v-icon>
          <!-- les nombres ici sont les nombres finaux -->
          <div class="home-our-database-grid-item-number">
            {{ cityArray.length }}
          </div>
          <p class="home-our-database-grid-item-text">
            {{ $t("home.home-database.statistic1") }}
          </p>
        </div>
        <div class="home-our-database-grid-item">
          <v-icon
            v-if="!isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="4vw"
            color="#edd036"
          >
            $vuetify.icon.area_yellow
          </v-icon>
          <v-icon
            v-if="isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="50px"
            color="#edd036"
          >
            $vuetify.icon.area_yellow
          </v-icon>
          <!-- les nombres ici sont les nombres finaux (en cours) -->
          <div class="home-our-database-grid-item-number">2 791 km²</div>
          <div class="home-our-database-grid-item-text">
            {{ $t("home.home-database.statistic2") }}
          </div>
        </div>
        <div class="home-our-database-grid-item">
          <v-icon
            v-if="!isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="4vw"
            color="#edd036"
          >
            $vuetify.icon.green_capita_yellow
          </v-icon>
          <v-icon
            v-if="isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="50px"
            color="#edd036"
          >
            $vuetify.icon.green_capita_yellow
          </v-icon>
          <!-- les nombres ici sont les nombres finaux -->
          <div class="home-our-database-grid-item-number">1 035 km²</div>
          <div class="home-our-database-grid-item-text">
            {{ $t("home.home-database.statistic3") }}
          </div>
        </div>
        <div class="home-our-database-grid-item">
          <v-icon
            v-if="!isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="4vw"
            color="#edd036"
          >
            $vuetify.icon.green_spaces_yellow
          </v-icon>
          <v-icon
            v-if="isMobile"
            class="mb-5 home-our-database-grid-item-img"
            size="50px"
            color="#edd036"
          >
            $vuetify.icon.green_spaces_yellow
          </v-icon>
          <!-- les nombres ici sont les nombres finaux -->
          <div class="home-our-database-grid-item-number">606 km²</div>
          <div class="home-our-database-grid-item-text">
            {{ $t("home.home-database.statistic4") }}
          </div>
        </div>
      </div>
    </div>

    <div id="home-our-dna" class="home-our-dna">
      <p class="home-our-dna-title">
        {{ $t("home.our-dna.title") }}
      </p>
      <div class="home-our-dna-grid">
        <div class="home-our-dna-img-div">
          <v-img
            :lazy-src="require('@/assets/images/Kermap_3D.jpg')"
            :src="require('@/assets/images/Kermap_3D.jpg')"
            class="home-our-dna-img"
          />
        </div>

        <div class="home-our-dna-text">
          <!-- <p class="home-our-dna-text-title">KERMAP DNA</p> -->
          <div class="home-our-dna-corps">
            {{ $t("home.our-dna.text") }}
          </div>
          <a
            class="home-our-dna-btn"
            :href="$i18n.locale === 'fr' ? '/propos' : '/about'"
            @mouseenter="pressOurDnaButton()"
          >
            {{ $t("home.our-dna.anchor") }}
            <v-icon class="home-our-dna-btn-icon" color="#113689"
              >mdi-chevron-right</v-icon
            >
          </a>
        </div>
      </div>
    </div>
    <Update v-if="isMobile" />
  </div>
</template>

<script>
import HomeHighlight from "@/components/Home/HomeHighlight.vue";
import HomeCityDiapo from "@/components/Home/HomeCityDiapo.vue";
import HomeWeather from "@/components/Home/HomeWeather.vue";
import kloverData from "@/assets/klover_data.json";
import Update from "@/components/Page/Update";

import axios from "axios";

export default {
  name: "Home",
  components: {
    HomeHighlight,
    HomeCityDiapo,
    HomeWeather,
    Update,
  },
  metaInfo() {
    var result;
    var resultTitle;
    if (window.location.pathname === "/accueil") {
      resultTitle = "Klover : végétation urbaine et changement climatique";
      result = [
        {
          name: "title",
          content: "Klover : végétation urbaine et changement climatique",
        },
        {
          name: "description",
          content:
            "Découvrez la place de la végétation urbaine et les effets du changement climatique dans 24 grandes villes du monde",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/home",
        },
        {
          property: "og:title",
          content: "Klover : végétation urbaine et changement climatique",
        },
        {
          property: "og:description",
          content:
            "Découvrez la place de la végétation urbaine et les effets du changement climatique dans 24 grandes villes du monde",
        },
        { property: "og:image", content: this.$t("meta.home.img") },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/meta-img/Klover-home-card.jpg",
        },
        {
          property: "twitter:title",
          content: "Klover : végétation urbaine et changement climatique",
        },
        {
          property: "twitter:description",
          content:
            "Découvrez la place de la végétation urbaine et les effets du changement climatique dans 24 grandes villes du monde",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Klover-home-card.jpg",
        },
      ];
    } else {
      resultTitle = "Klover : urban vegetation and climate change impact";
      result = [
        {
          name: "title",
          content: "Klover : urban vegetation and climate change impact",
        },
        {
          name: "description",
          content:
            "Get insights into urban greenness and the effects of climate change in 24 big cities around the world",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://klover.city/home" },
        {
          property: "og:title",
          content: "Klover : urban vegetation and climate change impact",
        },
        {
          property: "og:description",
          content:
            "Get insights into urban greenness and the effects of climate change in 24 big cities around the world",
        },
        {
          property: "og:image",
          content: "https://klover.city/meta-img/Klover-home-card-en.jpg",
        },
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:url", content: "https://klover.city/home" },
        {
          property: "twitter:title",
          content: "Klover : urban vegetation and climate change impact",
        },
        {
          property: "twitter:description",
          content:
            "Get insights into urban greenness and the effects of climate change in 24 big cities around the world",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Klover-home-card-en.jpg",
        },
      ];
    }
    return {
      title: resultTitle,
      meta: result,
    };
  },
  data: () => ({
    // the array which contains all the city data
    cityArray: Object.entries(kloverData),
    isMobile: false,
    showCities: false,
    highlightCity: null,
    lastUpdate: undefined,
  }),
  async created() {
    var self = this;
    await axios
      .get(process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/get_date_update")
      .then(function (response) {
        self.lastUpdate = response.data;
      });
  },
  mounted() {
    this.getWeatherData().then(() => {
      this.showCities = true;
      this.getHighlightCity();
    });

    this.isMobile = window.innerWidth <= 800;
    window.addEventListener("resize", (evt) => {
      this.foldHighlight(evt);
      this.isMobile = window.innerWidth <= 800;
    });
  },
  methods: {
    async getWeatherData() {
      let meanDeviationResponses;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationResponses = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/mean_deviation_yearly"
        );
        meanDeviationResponses = meanDeviationResponses.data;
        // Add weather data to the city array
        const meanDeviationResponsesArray = Object.entries(
          meanDeviationResponses
        );

        for (
          let index = 0;
          index < meanDeviationResponsesArray.length;
          index++
        ) {
          this.cityArray[index][1].weather = {};
          this.cityArray[index][1].weather.meanDeviationYearly =
            meanDeviationResponsesArray[index][1];
        }

        // sort cities by temperature difference
        this.cityArray.sort((a, b) => a.SurfTAHab < b.SurfTAHab);
      } catch (error) {
        console.error(error);
      }
    },
    getHighlightCity() {
      let maxDeviationCity = this.cityArray[0];
      this.HighlightCity = this.cityArray.forEach((city) => {
        if (
          Math.abs(
            city[1].weather.meanDeviationYearly.C[
              city[1].weather.meanDeviationYearly.C.length - 1
            ]
          ) >
          Math.abs(
            maxDeviationCity[1].weather.meanDeviationYearly.C[
              maxDeviationCity[1].weather.meanDeviationYearly.C.length - 1
            ]
          )
        ) {
          maxDeviationCity = city;
        }
      });

      this.highlightCity = maxDeviationCity;
    },
    // animation methods
    foldHighlight(event) {
      if (this.showCities) {
        if (window.matchMedia("(min-width: 800px)").matches && !event) {
          this.$anime({
            targets: ".highlight-card",
            translateX: "16",
            duration: 1500,
          });

          this.$anime({
            targets: ".highlight-img-div",
            translateX: "-16",
            translateY: "16",
            duration: 1500,
          });

          setTimeout(function () {
            document
              .getElementById("highlight-img-div")
              .classList.add("highlight-img-div-fold");
          }, 160);
        } else if (event && event.type === "resize") {
          this.$anime({
            targets: ".highlight-card",
            translateX: "0",
            duration: 1500,
          });

          this.$anime({
            targets: ".highlight-img-div",
            translateX: "0",
            translateY: "0",
            duration: 1500,
          });

          setTimeout(function () {
            document
              .getElementById("highlight-img-div")
              .classList.remove("highlight-img-div-fold");
          }, 20);
        }
      }
    },
    pressOurDnaButton() {
      this.$anime({
        targets: ".home-our-dna-btn-icon",
        translateX: 15,
        direction: "reverse",
        duration: 500,
        easing: "easeInOutElastic",
      });
    },
    pressButtonOnImg() {
      this.$anime({
        targets: ".home-btn-on-img-icon",
        rotate: "+=1turn",
      });
    },
  },
};
</script>

<style scoped>
.home-featured-btns {
  position: relative;
  color: #113689 !important;
  font-size: 20px;
  text-decoration: none;
  border-bottom: 2px solid #113689;
  padding-bottom: 4px;
  width: auto;
  transition: all 0.5s ease 0s;
}

.home-text-on-img {
  text-shadow: #1c632e 0 0 2vw;
}

.home-featured-btns:hover {
  padding-bottom: 8px;
}

.home-climate-img {
  margin: 8% 0% 3% 0%;
}

.home-climate-img-chart {
  position: absolute;
  bottom: 0;
}

.home-climate-img-text-section {
  max-width: 40%;
  margin-top: 5%;
  margin-left: 10%;
}

.home-climate-img-text-section-title {
  font-family: "Albra Bold";
  color: white;
  font-size: 3vw;
  margin-bottom: 5%;
  line-height: 3vw;
  font-display: swap;
}

.home-climate-img-text-section-content {
  color: white;
  font-size: 0.9vw;
}

.home-weather-text-bloc-title {
  font-size: 1.5vw;
  max-width: 20vw;
}

.home-earth-on-img {
  position: absolute;
  right: 11%;
  top: 50%;
  max-width: 40vw;
  min-height: 35%;
}

.home-klover-root-img {
  max-height: 15vw;
  max-width: 15vw;
}

.home-our-dna-img {
  max-height: 22vw;
  max-width: 30vw;
}

.home-img {
  height: 34vw;
}

@media screen and (max-width: 800px) {
  .home-presented-city-title {
    padding-top: 10%;
  }

  .home-presented-city-paragraph {
    padding: inherit;
    top: -60px;
  }

  .home-img {
    height: 530px;
    max-height: inherit;
  }

  .home-img-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin: 9% 0 0 15%;
  }

  .home-text-on-img {
    font-family: "Albra Bold", Helvetica, Arial, serif;
    font-size: 36px;
    line-height: 100%;
    font-weight: bold;
    color: white;
    max-width: 70vw;
    font-display: swap;
  }

  .home-btn-on-img {
    position: relative;
    color: #113689 !important;
    text-decoration: none;
    padding: 10px 15px 10px 15px;
    background-color: #ffffff;
    border-radius: 30px;
    font-size: 16px;
  }

  .home-btn-on-img-icon {
    position: relative;
  }

  .home-earth-on-img {
    position: relative;
    left: 1%;
    top: 0%;
    min-height: 90%;
    min-width: 90%;
    max-width: inherit;
  }

  .home-klover-root-div {
    position: relative;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    padding: 15% 2% 15% 2%;
    gap: 16px;
    background-color: #f4f3f1;
  }

  .home-klover-root-img {
    max-height: 20vw;
    max-width: 20vw;
  }

  .home-klover-root-title {
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    font-size: inherit;
    font-size: 16px;
    margin-bottom: 5%;
  }

  .home-klover-root-text {
    position: relative;
    font-size: 12px;
    margin-bottom: 5%;
    margin-top: 5%;
    max-width: inherit;
  }

  .home-klover-root-anchor {
    position: relative;
    color: #113689 !important;
    text-decoration: none;
    border-bottom: 2px solid #81b92f;
    padding-bottom: 4px;
    transition: all 0.5s ease-in-out;
    font-size: 16px;
  }

  .home-highlight {
    position: relative;
    padding-bottom: 75px;
    background-color: #58adee;
    background-size: cover;
  }

  .home-highlight-div-title {
    color: white;
  }

  .home-weather-text-bloc-title {
    font-size: 18px;
    line-height: 18px;
    max-width: inherit;
  }

  .home-klover-root-text {
    font-size: 14px;
  }

  .home-climate-img {
    height: 100%;
    padding-bottom: 5%;
  }

  .home-climate-img-text-section {
    max-width: inherit;
  }

  .home-climate-img-text-section-title {
    font-size: 20px;
    line-height: 20px;
  }

  .home-climate-img-text-section-content {
    font-size: 16px;
    max-width: 80vw;
  }

  .home-our-database-div {
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .home-our-database-grid {
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 2fr);
    gap: 18px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .home-our-database-grid-item {
    text-align: center;
  }

  .home-our-database-div {
    height: inherit;
  }

  .home-our-database-grid-item-number {
    font-size: 16px;
  }

  .home-our-database-grid-item-text {
    font-size: 16px;
  }

  .home-our-dna {
    padding-left: 10%;
    padding-right: 10%;
  }

  .home-our-dna-title {
    font-size: 24px;
  }

  .home-our-dna-grid {
    display: block;
    padding: 0;
  }

  .home-our-dna-text {
    padding: 0;
  }

  .home-our-dna-img-div {
    height: 100%;
  }

  .home-our-dna-img {
    max-height: 100%;
    max-width: 80vw;
    border-radius: 5px;
  }

  .home-our-dna-corps {
    font-size: 16px;
    margin-bottom: 5%;
  }

  .home-our-dna-text {
    text-align: center;
  }

  .home-our-dna-btn {
    font-size: 16px;
    margin-bottom: 15%;
  }

  .home-climate-img-chart {
    align-content: center;
    justify-content: center;
    justify-self: center;
    position: relative;
    left: 3%;
  }

  @media screen and (orientation: landscape) {
    .home-img {
      height: 50%;
    }

    .home-earth-on-img {
      position: absolute;
      left: 60%;
      top: 5%;
      max-height: 12%;
      max-width: 12%;
    }

    .home-btn-on-img {
      padding: 2% 3% 2% 3%;
    }

    .home-img-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin: 5% 0 0 5%;
    }
  }
}
</style>
