<template>
  <!-- Component used in Home page to present a specific city -->
  <div class="highlight-div">
    <div class="highlight-card">
      <v-icon
        class="highlight-card-icon"
        x-large
        color="#ffda36"
        @mouseenter="rotateIcon()"
      >
        mdi-white-balance-sunny
      </v-icon>
      <div class="highlight-title">
        {{ $t("city")[city[0]].name }}
      </div>
      <div class="highlight-subtitle">
        {{ $t("city." + city[0] + ".country") }}
      </div>
      <p class="highlight-text">
        {{ $t("city." + city[0] + ".shorten-presentation") }}
      </p>
      <div class="highlight-grid">
        <div class="highlight-grid-left">
          <v-icon
            class="highlight-grid-right-icon"
            color="#80ba28"
            :size="iconSize"
          >
            $vuetify.icons.klover_score
          </v-icon>
          <div>
            <div class="highlight-grid-right-number">
              {{ Math.round(city[1].PercVeg) }} <span class="unit">/100</span>
            </div>
            <div class="highlight-grid-right-text">
              {{ $t("home.home-highlight.data1") }}
            </div>
          </div>
        </div>

        <div class="highlight-grid-right">
          <v-icon
            class="highlight-grid-left-icon"
            color="green"
            :size="iconSize"
          >
            $vuetify.icons.mean_deviation
          </v-icon>
          <div>
            <div v-if="city[1].weather" class="highlight-grid-left-number">
              {{
                (city[1].weather.meanDeviationYearly.C[
                  city[1].weather.meanDeviationYearly.C.length - 1
                ] >= 0
                  ? "+"
                  : "") +
                $n(
                  city[1].weather.meanDeviationYearly.C[
                    city[1].weather.meanDeviationYearly.C.length - 1
                  ].toFixed(2)
                ) +
                "°C"
              }}
            </div>
            <div class="highlight-grid-left-text">
              {{ $t("home.home-highlight.data2") }}<b>{{ currentYear }}</b>
            </div>
          </div>
        </div>
      </div>

      <a
        class="highlight-btn"
        :href="cityUrl"
        @mouseenter="learnMoreAnimation()"
      >
        {{ $t("home.home-highlight.button") }}
        <v-icon class="highlight-btn-icon" color="#113689"
          >mdi-chevron-right</v-icon
        >
      </a>
    </div>
    <div id="highlight-img-div" ref="highlightImgDiv" class="highlight-img-div">
      <v-img
        height="100%"
        :src="require('@/assets/images/' + city[0] + '.jpg')"
        :lazy-src="require('@/assets/images/' + city[0] + '.jpg')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeHighlight",
  filters: {
    // shorten the population number
    getRound: function (value) {
      if (value > 999 && value < 1000000) {
        return (value / 1000).toFixed(1) + "K";
      } else if (value > 1000000) {
        return (value / 1000000).toFixed(1) + "M";
      } else if (value < 900) {
        return value;
      }
    },
  },
  props: {
    // the city object displayed on the component
    city: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      cityUrl: "",
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    iconSize() {
      switch (this.$vuetify.breakpoint.name) {
        default:
          return "";
        case "xs":
          return "40px";
        case "sm":
          return "40px";
        case "md":
          return "3vw";
        case "lg":
          return "3vw";
        case "xl":
          return "3vw";
      }
    },
  },
  mounted() {
    // generate the URL of the city page of the city displayed on the component
    if (this.$i18n.locale === "fr") {
      this.cityUrl = "/ville/" + this.city[0];
    } else {
      this.cityUrl = "/" + this.city[0];
    }
  },
  methods: {
    // animation methods
    learnMoreAnimation() {
      this.$anime({
        targets: ".highlight-btn-icon",
        translateX: 15,
        direction: "reverse",
        duration: 500,
        easing: "easeInOutElastic",
      });
    },
    rotateIcon() {
      this.$anime({
        targets: ".highlight-card-icon",
        rotate: "+=1turn",
      });
    },
  },
};
</script>

<style scoped>
.unit {
  font-size: 0.7vw;
}

.highlight-grid-right-icon {
  font-size: 3vw;
  height: 3vw;
  width: 3vw;
}

@media screen and (max-width: 800px) {
  .highlight-div {
    display: block;
    margin: 0 5% 0 5%;
  }

  .highlight-card {
    border-radius: 7px 7px 0 0;
    padding: 10% 10% 10% 20%;
  }

  .highlight-title {
    position: relative;
    font-family: "Albra Bold";
    font-size: 32px;
    font-display: swap;
  }

  .highlight-text {
    position: relative;
    padding-top: 5%;
    font-size: 12px;
  }

  .highlight-grid {
    position: relative;
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 16px;
    padding: 16px 0 16px 0;
  }

  .highlight-grid-left {
    position: relative;
    display: block;
    grid-template-columns: 1fr 2.5fr;
    align-items: start;
    gap: 5px;
  }

  .highlight-grid-left-number {
    position: relative;
    font-weight: bold;
    font-size: 16px;
  }

  .highlight-grid-right {
    position: relative;
    display: block;
    grid-template-columns: 1fr 2.5fr;
    align-items: start;
    gap: 5px;
  }

  .highlight-grid-right-number {
    position: relative;
    font-weight: bold;
    font-size: 16px;
  }

  .highlight-grid-left-text {
    position: relative;
    font-size: 12px;
  }

  .highlight-grid-right-text {
    position: relative;
    font-size: 12px;
  }

  .highlight-grid-left-icon,
  .highlight-grid-right-icon {
    position: relative;
    top: -5px;
  }

  .unit {
    font-size: 12px;
  }

  .highlight-img-div {
    height: 300px;
  }
}
</style>
