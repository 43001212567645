<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="snackbar" color="#94c081">
      {{ $t("update.content") }}
      <span style="font-weight: bold">{{ lastUpdate }}</span>
      <v-btn
        color="white"
        class="ml-2"
        style="text-transform: inherit"
        text
        @click="snackbar = false"
      >
        Fermer
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Update",
  data() {
    return {
      snackbar: true,
      lastUpdate: "",
    };
  },
  async created() {
    var self = this;
    await axios
      .get(process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/get_date_update")
      .then(function (response) {
        self.lastUpdate = response.data;
      });
  },
};
</script>
