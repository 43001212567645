<template>
  <!-- Slider used in Home page -->
  <div class="city-diapo-div">
    <div v-if="!isMobile" class="city-diapo-buttons">
      <a
        ref="cityDiapoPrevButton"
        class="city-diapo-prev-button"
        @click="previousCards()"
      >
        <v-icon color="#113689">mdi-ray-end-arrow</v-icon>
      </a>
      <a
        ref="cityDiapoNextButton"
        class="city-diapo-next-button"
        @click="nextCards()"
      >
        <v-icon>mdi-ray-start-arrow</v-icon>
      </a>
    </div>
    <v-slide-group
      class="city-diapo-slide-group"
      mobile-breakpoint="xs"
      :show-arrows="false"
      center-active
    >
      <v-slide-item
        v-for="city in shownCities"
        :key="city[0]"
        class="city-diapo-slide-item"
      >
        <CityCard :is-mobile="isMobile" :city="city" />
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import CityCard from "@/components/Description/CityCard.vue";

export default {
  name: "HomeCityDiapo",
  components: {
    CityCard,
  },
  props: {
    // the city object displayed on the component
    cities: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // the JSON which contains all the city data
      cityArray: this.cities,
      // array of the tenth shown cities
      shownCities: [],
    };
  },

  async mounted() {
    this.selectshownCities();
    this.$refs.cityDiapoNextButton.children[0].classList.remove("theme--light");
    this.$refs.cityDiapoPrevButton.children[0].classList.remove("theme--light");
    this.disablePrevButton();
  },
  methods: {
    // select the tenth shown cities
    selectshownCities() {
      // push the tenth shown cities to shownCities array
      for (
        let index = 0;
        index < this.cityArray.length && index < 10;
        index++
      ) {
        this.shownCities.push(this.cityArray[index]);
      }
    },
    // redirect button click event to v-slide-group
    nextCards() {
      var event = new MouseEvent("click");
      var nextButton = document.querySelector(".v-slide-group__next");
      nextButton.dispatchEvent(event);
      this.$refs.cityDiapoPrevButton.children[0].style.color = "#113689";
      setTimeout(this.disableNextButton, 10);
    },
    // redirect button click event to v-slide-group
    previousCards() {
      var event = new MouseEvent("click");
      var nextButton = document.querySelector(".v-slide-group__prev");
      nextButton.dispatchEvent(event);
      this.$refs.cityDiapoNextButton.children[0].style.color = "#113689";
      setTimeout(this.disablePrevButton, 10);
    },
    // disable next button when we are at the end of the slide group
    disableNextButton() {
      var nextButton = document.querySelector(".v-slide-group__next");
      if (nextButton.classList.length == 2) {
        this.$refs.cityDiapoNextButton.children[0].style.color = "grey";
      }
    },
    // disable next button when we are at the start of the slide group
    disablePrevButton() {
      var prevButton = document.querySelector(".v-slide-group__prev");
      if (prevButton.classList.length == 2) {
        this.$refs.cityDiapoPrevButton.children[0].style.color = "grey";
      }
    },
  },
};
</script>

<style>
.v-slide-group__prev,
.v-slide-group__next {
  display: none;
}

.v-slide-group__content {
  padding-left: 12%;
  padding-right: 12%;
}

@media only screen and (max-width: 800px) {
  .v-slide-group__wrapper {
    touch-action: pan-x !important;
  }

  .v-slide-group__content {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media screen and (orientation: landscape) {
    .v-slide-group__wrapper {
      touch-action: auto !important;
    }
  }
}

.city-diapo-slide-item-more {
  position: relative;
  background-color: white;
  padding: 18px;
  border-radius: 100px;
  align-self: center;
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.26);
  transition: all 0.1s ease-in-out;
}

.city-diapo-slide-item-more:hover {
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.055) !important;
}
</style>
