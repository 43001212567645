<template>
  <div id="home-weather">
    <div>
      <p class="home-weather-chart-title">
        {{ $t("home.home-text-vegetation.legend1") }}
      </p>
      <p class="home-weather-chart-subtitle mb-8">
        {{ $t("home.home-text-vegetation.legend2") }}
      </p>
      <canvas
        v-if="weather.intervalYearlyMean"
        ref="globalChart"
        class="home-weather-chart"
      ></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  name: "HomeWeather",
  data: () => ({
    weather: null,
    globalChart: null,
    gradient: null,
  }),
  async mounted() {
    await this.loadWeatherData();
    this.addYearlyMeanChart();
  },
  methods: {
    async loadWeatherData() {
      let nPIntervalYearlyMeanResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        nPIntervalYearlyMeanResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/interval_mean_yearly"
        );
        nPIntervalYearlyMeanResponse = nPIntervalYearlyMeanResponse.data;
        if (!this.weather) {
          this.weather = {};
        }
        this.weather.intervalYearlyMean = nPIntervalYearlyMeanResponse;
      } catch (error) {
        console.error(error);
      }
      let nPYearlyMeanResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        nPYearlyMeanResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/mean_yearly"
        );
        nPYearlyMeanResponse = nPYearlyMeanResponse.data;
        if (!this.weather) {
          this.weather = {};
        }
        this.weather.yearlyMean = nPYearlyMeanResponse;

        this.weather.yearlyMean.temp = new Array(
          this.weather.intervalYearlyMean.temp.length
        )
          .fill(null)
          .concat(this.weather.yearlyMean.temp);
        this.weather.yearlyMean.temp.pop();

        this.weather.yearlyMean.chaleur = new Array(
          this.weather.intervalYearlyMean.chaleur.length
        )
          .fill(null)
          .concat(this.weather.yearlyMean.chaleur);
      } catch (error) {
        console.error(error);
      }
    },

    addYearlyMeanChart() {
      this.gradient = [
        "#ebb338",
        "#eba931",
        "#eda02c",
        "#ef8d1e",
        "#f47a13",
        "#f4700c",
        "#f66806",
        "#f75c00",
      ];
      var self = this;

      Chart.defaults.font.family = "BR Candor";

      this.globalChart = new Chart(this.$refs.globalChart, {
        type: "bar",
        data: {
          labels: this.weather.intervalYearlyMean.annee.concat(
            this.weather.yearlyMean.annee.map(String)
          ),
          datasets: [
            {
              label: "Nombre cumulé de jours anormalement chauds",
              backgroundColor: this.gradient,
              borderColor: "#ffda36",
              data: self.weather.intervalYearlyMean.chaleur,
              yAxisID: "y",
              barPercentage: 1.0,
              categoryPercentage: 1.0,
            },
          ],
        },
        options: {
          elements: {
            line: {
              tension: 0.3,
              cubicInterpolationMonde: "monotone",
            },
            point: {
              pointBorderWidth: 0,
              pointBackgroundColor: "#113689",
              pointBorderColor: "#113689",
              hoverRadius: 6,
              hoverBorderWidth: 2,
              pointHoverBackgroundColor: "#ffffff",
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              position: "nearest",
              backgroundColor: "rgb(255,255,255,0.9)",
              titleColor: "rgb(0,0,0)",
              bodyColor: "rgb(0,0,0)",
              bodySpacing: 4,
              caretPadding: 5,
              borderWidth: 0.7,
              borderColor: "rgba(217, 217, 217)",
              callbacks: {
                label: function (context) {
                  return context.dataset.data[context.dataIndex];
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "category",
              max: "2016-2020",
              grid: {
                display: false,
              },
            },
            y: {
              type: "linear",
              position: "left",
              grid: {
                display: true,
                tickLength: 0,
                drawBorder: false,
              },
              ticks: {
                stepSize: 250,
                padding: 15,
              },
            },
          },
        },
      });
    },
    // Toggle the precise chart
    toggleInterval(evt) {
      // If the precise datasets are already displayed
      if (
        document.getElementsByClassName("home-weather-chart-button-active")
          .length
      ) {
        // the interval datasets are displayed and the precise ones are hidden
        for (let index = 0; index < 2; index++) {
          this.globalChart.setDatasetVisibility(index, true);
        }
        for (let index = 2; index < 4; index++) {
          this.globalChart.setDatasetVisibility(index, false);
        }

        // the min is deleted and we set a max
        this.globalChart.options.scales["x"].min = undefined;
        this.globalChart.options.scales["x"].max = "2016-2020";

        // Change the styles of the buttons
        let toggleBtn = document.getElementsByClassName(
          "home-weather-chart-button-active"
        );

        for (let btn of toggleBtn) {
          btn.classList.remove("home-weather-chart-button-active");
        }

        // Change the displayed legend
        this.globalChart.options.plugins.legend.labels.filter = function (
          item
        ) {
          return item.datasetIndex !== 2 && item.datasetIndex !== 3;
        };
      } else {
        for (let index = 0; index < 2; index++) {
          this.globalChart.setDatasetVisibility(index, false);
        }
        for (let index = 2; index < 4; index++) {
          this.globalChart.setDatasetVisibility(index, true);
        }
        this.globalChart.options.scales["x"].min = "1981";
        this.globalChart.options.scales["x"].max = undefined;

        evt.target.classList.toggle("home-weather-chart-button-active");

        this.globalChart.options.plugins.legend.labels.filter = function (
          item
        ) {
          return item.datasetIndex !== 0 && item.datasetIndex !== 1;
        };
      }
      // Update the chart
      this.globalChart.update();
    },
  },
};
</script>

<style scoped>
.home-weather-chart {
  background-color: white;
  border-radius: 15px;
  padding: 1% 3% 1% 1%;
}

.home-weather-chart-subtitle {
  color: #aabcc6;
  font-size: 0.8vw;
}

@media screen and (max-width: 800px) {
  .home-weather-chart-title {
    font-size: 18px;
  }

  .home-weather-chart-subtitle {
    color: #aabcc6;
    font-size: 0.8vw;
  }

  .home-weather-chart {
    max-height: 40vh;
    max-width: 90vw;
  }

  @media screen and (orientation: landscape) {
    .home-weather-chart {
      max-height: 80vh;
    }
  }
}
</style>
